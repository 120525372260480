.container-tela-cpf {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
}

.container-tela-cpf-items {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	animation: openBoleto 2s;
}

.container-tela-cpf-footer {
	display: flex;
	justify-content: center;
	width: 100%;
}

.container-tela-cpf-img {
	background: white;
	border-radius: 15px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.container-tela-cpf-img img:first-child{
	margin-top: 50px;
}

.container-tela-cpf-img img:last-child{
	margin-top: 25px;
}

.container-tela-cpf-forms {
	display: flex;
	flex-direction: column;
}

.form-telacpf {
	border: solid 2px whitesmoke;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.container-tela-cpf-text {
	text-align: left;
}

.cabecalho-telacpf div {
	text-align: left;
}

.tela-cpf-nao-conheco {
	font-family: Roboto-Regular;
	background-size: cover;
	background-color: #383838;
	border: none;
	outline: none;
	color: white;
	border-radius: 15px;
	text-align: center;
	width: fit-content;
}

.tela-cpf-acessar {
	font-family: Roboto-Regular;
	border: none;
	outline: none;
	color: white;
	border-radius: 15px;
}

.cpf {
	font-family: Roboto-Regular;
	border: none;
	outline: none;
	background: transparent;
	text-align: right;
	color: white;
}

.cpf::placeholder {
	font-family: Roboto-Regular;
}

.cnpj {
	font-family: Roboto-Regular;
	border: none;
	outline: none;
	background: transparent;
	text-align: right;
	color: white;
}

.label-cpf {
	font-family: Roboto-Regular;
}

.label-cnpj {
	font-family: Roboto-Regular;
}

@media only screen and (min-width: 720px) {
	.container-tela-cpf {
		height: 100vh;
		min-height: 450px;
	}

	.container-tela-cpf-items {
		width: 100%;
	}

	.container-tela-cpf-footer {
		width: 100%;
		margin-bottom: 20px;
	}

	.container-tela-cpf-img {
		border-radius: 15px;
		height: 100%;
		padding-right: 25px;
		padding-left: 25px;
		margin: 10px;
	}

	.container-tela-cpf-forms {
		min-width: 320px;
		width: 400px;
		margin: 10px;
	}

	.form-telacpf {
		border-radius: 50px;
		margin-bottom: 2%;
		padding: 5px;
		width: 70%;
	}

	.tela-cpf-nao-conheco {
		border-radius: 15px;
		margin-top: 10px;
		padding: 5px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 0.9em;
		text-align: center;
		width: fit-content;
	}

	.tela-cpf-acessar {
		padding-top: 1%;
		padding-bottom: 1%;
		border-radius: 15px;
		font-size: 0.9em;
		padding: 5px;
		padding-left: 10px;
		padding-right: 10px;
		background-color: #383838;
	}

	.cpf {
		font-size: 1.2em;
		width: 60px;
	}

	.cpf::placeholder {
		letter-spacing: 0px;
	}

	.cnpj {
		font-size: 1em;
		width: 35px;
	}

	.label-cpf {
		font-size: 1.2em;
	}

	.label-cnpj {
		font-size: 1em;
	}
}

@media only screen and (max-width: 720px) {
	.container-tela-cpf {
		min-height: 95vh;
	}

	.container-tela-cpf-items {
		display: flex;
		flex-direction: column;
		width: 90%;
		justify-content: center;
		align-items: center;
		animation: openBoleto 2s;
	}

	.container-tela-cpf-footer {
		width: 100%;
		margin-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.tela-cpf-img {
		min-width: 200px;
		min-height: 50px;
	}

	.container-tela-cpf-img {
		border-radius: 15px;
		height: 100%;
		padding: 20px;
		margin: 10px;
	}

	.container-tela-cpf-forms {
		margin: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.form-telacpf {
		border-radius: 50px;
		margin-bottom: 2%;
		padding: 5px;
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}

	.tela-cpf-nao-conheco {
		border-radius: 15px;
		margin-top: 10px;
		padding: 5px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 0.9em;
		text-align: center;
		width: 100%;
	}

	.tela-cpf-acessar {
		padding-top: 1%;
		padding-bottom: 1%;
		border-radius: 15px;
		font-size: 0.9em;
		padding: 5px;
		padding-left: 15px;
		padding-right: 15px;
		margin-right: 5px;
		background-color: #383838;
	}

	.cpf {
		font-size: 1.2em;
		width: 60px;
	}

	.cpf::placeholder {
		letter-spacing: 0.1px;
	}

	.cnpj {
		font-size: 1em;
		width: 35px;
	}

	.label-cpf {
		font-size: 1.2em;
	}

	.label-cnpj {
		font-size: 1em;
	}
}

.Button-cpf {
	font-family: museo;
	background-color: #da2129;
	padding-top: 2%;
	padding-bottom: 2%;
	padding-left: 6%;
	padding-right: 6%;
	text-align: center;
	border: none;
	outline: none;
	color: white;
	border-radius: 10px;
}

.row {
	animation: openBoleto 2s;
}

.row:before,
.row:after {
	content: '';
	display: table;
}

.row:after {
	clear: both;
}

.col {
	display: inline-block;
	vertical-align: top;
	min-height: 1px;
	width: 100%;
	*zoom: 1;
	*display: inline;
}

.col-tam-img {
	width: 85%;
}

.col-tam-txt {
	width: 75%;
}

@media (min-width: 480px) {
	.col-2 {
		width: 30%;
	}
	.col-tam-txt {
		width: 30%;
		font-size: 19px;
	}
	.ajust-img-aling {
		display: flex;
		justify-content: flex-end !important;
		margin-right: 15px;
		align-items: flex-end;
	}
}

.input {
	border: solid 1px whitesmoke;
	border-radius: 50px;
	color: white;
	width: 68%;
	height: auto;
	display: flex;
	justify-content: center;
	font-size: 1em;
	align-items: center;
	margin-bottom: 2%;
	font-family: museo700;
}

@media (max-width: 1320px) {
	.input {
		width: 90%;
	}

	.button-cpf-cliente {
		margin-left: -38%;
	}
}

@media (max-width: 1200px) {
	.input {
		width: 90%;
	}
}

@media (max-width: 600px) {
	.input {
		width: 100%;
	}
}

::-webkit-input-placeholder {
	color: white;
}

:-moz-placeholder {
	/* Firefox 18- */
	color: white;
}

:-ms-input-placeholder {
	color: white;
}

.label_cnpj {
	font-size: 0.8em;
}

.ajust-img-aling {
	display: flex;
	justify-content: center;
	align-items: center;
}

.barra-title {
	width: 100%;
}

.border-barra-title {
	height: auto;
	width: 70%;
	padding-top: 10%;
	padding-bottom: 10%;
	padding-left: 5%;
	padding-right: 5%;
	margin-top: 20%;
	border: 2px solid white;
	border-radius: 15px;
	background-color: white;
}

.textoCPF {
	color: white;
	text-align: justify;
	font-family: Roboto-Regular;
	font-size: 1em;
	word-break: normal;
}

.modal {
	position: absolute;
	width: 400 !important;
}

@media (max-width: 600px) {
	.Container-cpf {
		justify-content: flex-start;
	}
	.border-barra-title {
		margin-top: 35%;
	}
}

.cabecalho-telacpf {
	text-align: center;
	color: white;
	font-family: Roboto-Bold;
	font-weight: bold;
	font-size: 1.7em;
	padding-top: 0;
	padding-bottom: 0;
	width: 250px;
}

@media (max-width: 1320px) {
	.container-form-telacpf {
		width: 90%;
	}

	.button-cpf-cliente {
		margin-left: -38%;
	}
}

@media (max-width: 1200px) {
	.container-form-telacpf {
		width: 90%;
	}
}

@media (max-width: 600px) {
	.container-form-telacpf {
		width: 100%;
	}
}
/* <div className="border-barra-title"> */
