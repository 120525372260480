.container-error-modal {
    position: fixed;
    top: 0px;
    min-height: 100vh;
    min-width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrap-error-modal {
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    width: 90%;
    max-width: 450px;
    min-height: 300px;
    max-height: 80vh;
    border-radius: 5px;
    padding: 25px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    animation: open 0.4s ease-in-out;
}

.error-modal-message {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.5em;
}

.error-modal-button {
    border: none;
    outline: none;
    color: whitesmoke;
    height: 30px;
    width: 125px; 
    text-align: center;
    background: #da2129;
    font-family: museo700;
    padding-top: 5%;
    padding-bottom: 5%;
    color: white;
    border-radius: 30px 30px 30px;
    margin-bottom: 5%;
    font-weight: bold;
}

.error-modal-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-modal-image img {
    display: flex;
    width: 150px;
    height: 75px;
}

@keyframes open {

    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }

  }