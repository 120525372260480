@font-face {
	font-family: Roboto-Regular;
	src: url(/static/media/Roboto-Regular.18d44f79.ttf);
}

@font-face {
	font-family: Roboto-Bold;
	src: url(/static/media/Roboto-Bold.7c181887.ttf);
}

@font-face {
	font-family: museo;
	src: url(/static/media/MuseoSansCyrl.woff.1a65aea0.ttf);
}
@font-face {
	font-family: museo700;
	src: url(/static/media/museosanscyrl-700.b4abf66b.ttf);
}

@font-face {
	font-family: museo900;
	src: url(/static/media/Museo900-Regular.4e1d39b1.otf);
}

body {
	margin: 0;
	font-family: Roboto-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	min-height: 100vh;
	background: url(/static/media/background.041890ac.png) no-repeat;
	background-size: cover;
	box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
	font-family: Roboto-Regular;
	cursor: pointer;
}

@-webkit-keyframes openBoleto {
	0% {
		opacity: 0.6;
		-webkit-transform: translateY(10%);
		        transform: translateY(10%);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}
}

@keyframes openBoleto {
	0% {
		opacity: 0.6;
		-webkit-transform: translateY(10%);
		        transform: translateY(10%);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}
}

.lds-container {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0px;
	z-index: 5;
	background-color: rgba(0, 0, 0, 0.7);
}

.lds-ellipsis {
	display: inline-block;
	position: absolute;
	width: 64px;
	height: 64px;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.lds-ellipsis div {
	position: absolute;
	top: 27px;
	width: 11px;
	height: 11px;
	border-radius: 50%;
	background: #fff;
	-webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
	        animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
	left: 6px;
	-webkit-animation: lds-ellipsis1 0.6s infinite;
	        animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
	left: 6px;
	-webkit-animation: lds-ellipsis2 0.6s infinite;
	        animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
	left: 26px;
	-webkit-animation: lds-ellipsis2 0.6s infinite;
	        animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
	left: 45px;
	-webkit-animation: lds-ellipsis3 0.6s infinite;
	        animation: lds-ellipsis3 0.6s infinite;
}

@-webkit-keyframes lds-ellipsis1 {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}

	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@keyframes lds-ellipsis1 {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}

	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@-webkit-keyframes lds-ellipsis3 {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	100% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	100% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
}

@-webkit-keyframes lds-ellipsis2 {
	0% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}

	100% {
		-webkit-transform: translate(19px, 0);
		        transform: translate(19px, 0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}

	100% {
		-webkit-transform: translate(19px, 0);
		        transform: translate(19px, 0);
	}
}

.container-tela-cpf {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
}

.container-tela-cpf-items {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	-webkit-animation: openBoleto 2s;
	        animation: openBoleto 2s;
}

.container-tela-cpf-footer {
	display: flex;
	justify-content: center;
	width: 100%;
}

.container-tela-cpf-img {
	background: white;
	border-radius: 15px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.container-tela-cpf-img img:first-child{
	margin-top: 50px;
}

.container-tela-cpf-img img:last-child{
	margin-top: 25px;
}

.container-tela-cpf-forms {
	display: flex;
	flex-direction: column;
}

.form-telacpf {
	border: solid 2px whitesmoke;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.container-tela-cpf-text {
	text-align: left;
}

.cabecalho-telacpf div {
	text-align: left;
}

.tela-cpf-nao-conheco {
	font-family: Roboto-Regular;
	background-size: cover;
	background-color: #383838;
	border: none;
	outline: none;
	color: white;
	border-radius: 15px;
	text-align: center;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.tela-cpf-acessar {
	font-family: Roboto-Regular;
	border: none;
	outline: none;
	color: white;
	border-radius: 15px;
}

.cpf {
	font-family: Roboto-Regular;
	border: none;
	outline: none;
	background: transparent;
	text-align: right;
	color: white;
}

.cpf::-webkit-input-placeholder {
	font-family: Roboto-Regular;
}

.cpf::placeholder {
	font-family: Roboto-Regular;
}

.cnpj {
	font-family: Roboto-Regular;
	border: none;
	outline: none;
	background: transparent;
	text-align: right;
	color: white;
}

.label-cpf {
	font-family: Roboto-Regular;
}

.label-cnpj {
	font-family: Roboto-Regular;
}

@media only screen and (min-width: 720px) {
	.container-tela-cpf {
		height: 100vh;
		min-height: 450px;
	}

	.container-tela-cpf-items {
		width: 100%;
	}

	.container-tela-cpf-footer {
		width: 100%;
		margin-bottom: 20px;
	}

	.container-tela-cpf-img {
		border-radius: 15px;
		height: 100%;
		padding-right: 25px;
		padding-left: 25px;
		margin: 10px;
	}

	.container-tela-cpf-forms {
		min-width: 320px;
		width: 400px;
		margin: 10px;
	}

	.form-telacpf {
		border-radius: 50px;
		margin-bottom: 2%;
		padding: 5px;
		width: 70%;
	}

	.tela-cpf-nao-conheco {
		border-radius: 15px;
		margin-top: 10px;
		padding: 5px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 0.9em;
		text-align: center;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}

	.tela-cpf-acessar {
		padding-top: 1%;
		padding-bottom: 1%;
		border-radius: 15px;
		font-size: 0.9em;
		padding: 5px;
		padding-left: 10px;
		padding-right: 10px;
		background-color: #383838;
	}

	.cpf {
		font-size: 1.2em;
		width: 60px;
	}

	.cpf::-webkit-input-placeholder {
		letter-spacing: 0px;
	}

	.cpf::placeholder {
		letter-spacing: 0px;
	}

	.cnpj {
		font-size: 1em;
		width: 35px;
	}

	.label-cpf {
		font-size: 1.2em;
	}

	.label-cnpj {
		font-size: 1em;
	}
}

@media only screen and (max-width: 720px) {
	.container-tela-cpf {
		min-height: 95vh;
	}

	.container-tela-cpf-items {
		display: flex;
		flex-direction: column;
		width: 90%;
		justify-content: center;
		align-items: center;
		-webkit-animation: openBoleto 2s;
		        animation: openBoleto 2s;
	}

	.container-tela-cpf-footer {
		width: 100%;
		margin-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.tela-cpf-img {
		min-width: 200px;
		min-height: 50px;
	}

	.container-tela-cpf-img {
		border-radius: 15px;
		height: 100%;
		padding: 20px;
		margin: 10px;
	}

	.container-tela-cpf-forms {
		margin: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.form-telacpf {
		border-radius: 50px;
		margin-bottom: 2%;
		padding: 5px;
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}

	.tela-cpf-nao-conheco {
		border-radius: 15px;
		margin-top: 10px;
		padding: 5px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 0.9em;
		text-align: center;
		width: 100%;
	}

	.tela-cpf-acessar {
		padding-top: 1%;
		padding-bottom: 1%;
		border-radius: 15px;
		font-size: 0.9em;
		padding: 5px;
		padding-left: 15px;
		padding-right: 15px;
		margin-right: 5px;
		background-color: #383838;
	}

	.cpf {
		font-size: 1.2em;
		width: 60px;
	}

	.cpf::-webkit-input-placeholder {
		letter-spacing: 0.1px;
	}

	.cpf::placeholder {
		letter-spacing: 0.1px;
	}

	.cnpj {
		font-size: 1em;
		width: 35px;
	}

	.label-cpf {
		font-size: 1.2em;
	}

	.label-cnpj {
		font-size: 1em;
	}
}

.Button-cpf {
	font-family: museo;
	background-color: #da2129;
	padding-top: 2%;
	padding-bottom: 2%;
	padding-left: 6%;
	padding-right: 6%;
	text-align: center;
	border: none;
	outline: none;
	color: white;
	border-radius: 10px;
}

.row {
	-webkit-animation: openBoleto 2s;
	        animation: openBoleto 2s;
}

.row:before,
.row:after {
	content: '';
	display: table;
}

.row:after {
	clear: both;
}

.col {
	display: inline-block;
	vertical-align: top;
	min-height: 1px;
	width: 100%;
	*zoom: 1;
	*display: inline;
}

.col-tam-img {
	width: 85%;
}

.col-tam-txt {
	width: 75%;
}

@media (min-width: 480px) {
	.col-2 {
		width: 30%;
	}
	.col-tam-txt {
		width: 30%;
		font-size: 19px;
	}
	.ajust-img-aling {
		display: flex;
		justify-content: flex-end !important;
		margin-right: 15px;
		align-items: flex-end;
	}
}

.input {
	border: solid 1px whitesmoke;
	border-radius: 50px;
	color: white;
	width: 68%;
	height: auto;
	display: flex;
	justify-content: center;
	font-size: 1em;
	align-items: center;
	margin-bottom: 2%;
	font-family: museo700;
}

@media (max-width: 1320px) {
	.input {
		width: 90%;
	}

	.button-cpf-cliente {
		margin-left: -38%;
	}
}

@media (max-width: 1200px) {
	.input {
		width: 90%;
	}
}

@media (max-width: 600px) {
	.input {
		width: 100%;
	}
}

::-webkit-input-placeholder {
	color: white;
}

:-moz-placeholder {
	/* Firefox 18- */
	color: white;
}

:-ms-input-placeholder {
	color: white;
}

.label_cnpj {
	font-size: 0.8em;
}

.ajust-img-aling {
	display: flex;
	justify-content: center;
	align-items: center;
}

.barra-title {
	width: 100%;
}

.border-barra-title {
	height: auto;
	width: 70%;
	padding-top: 10%;
	padding-bottom: 10%;
	padding-left: 5%;
	padding-right: 5%;
	margin-top: 20%;
	border: 2px solid white;
	border-radius: 15px;
	background-color: white;
}

.textoCPF {
	color: white;
	text-align: justify;
	font-family: Roboto-Regular;
	font-size: 1em;
	word-break: normal;
}

.modal {
	position: absolute;
	width: 400 !important;
}

@media (max-width: 600px) {
	.Container-cpf {
		justify-content: flex-start;
	}
	.border-barra-title {
		margin-top: 35%;
	}
}

.cabecalho-telacpf {
	text-align: center;
	color: white;
	font-family: Roboto-Bold;
	font-weight: bold;
	font-size: 1.7em;
	padding-top: 0;
	padding-bottom: 0;
	width: 250px;
}

@media (max-width: 1320px) {
	.container-form-telacpf {
		width: 90%;
	}

	.button-cpf-cliente {
		margin-left: -38%;
	}
}

@media (max-width: 1200px) {
	.container-form-telacpf {
		width: 90%;
	}
}

@media (max-width: 600px) {
	.container-form-telacpf {
		width: 100%;
	}
}
/* <div className="border-barra-title"> */

.logo-erro {
    width: 45%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.container-erro {
    background-color: #373736;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-around;
}

.container-erro-items {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.container-erro-label {
    color: white;
    font-weight: bold;
    font-size: 1.5em;
}
.container-error-modal {
    position: fixed;
    top: 0px;
    min-height: 100vh;
    min-width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrap-error-modal {
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    width: 90%;
    max-width: 450px;
    min-height: 300px;
    max-height: 80vh;
    border-radius: 5px;
    padding: 25px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    -webkit-animation: open 0.4s ease-in-out;
            animation: open 0.4s ease-in-out;
}

.error-modal-message {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.5em;
}

.error-modal-button {
    border: none;
    outline: none;
    color: whitesmoke;
    height: 30px;
    width: 125px; 
    text-align: center;
    background: #da2129;
    font-family: museo700;
    padding-top: 5%;
    padding-bottom: 5%;
    color: white;
    border-radius: 30px 30px 30px;
    margin-bottom: 5%;
    font-weight: bold;
}

.error-modal-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-modal-image img {
    display: flex;
    width: 150px;
    height: 75px;
}

@-webkit-keyframes open {

    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }

  }

@keyframes open {

    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }

  }
.container-detalhes-modal {
    position: fixed;
    top: 0px;
    min-height: 100vh;
    min-width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
}

.wrap-detalhes-modal {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 90%;
    max-width: 450px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 300px;
    max-height: 80vh;
    margin: auto;
    border-radius: 15px;
    padding: 15px;
    box-sizing: border-box;
    -webkit-animation: open 0.4s ease-in-out;
            animation: open 0.4s ease-in-out;
}

.wrap-detalhes-modal::-webkit-scrollbar {
    width: 5px;
}
  
  /* Track */
.wrap-detalhes-modal::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
  
  /* Handle */
.wrap-detalhes-modal::-webkit-scrollbar-thumb {
    background: red; 
    border-radius: 10px;
}


.card-detalhe-parcela-numero>span:last-of-type {
    font-size: 30px;
    font-weight: bold;
    color: orange;
    line-height: 50px;
}

.wrap-valor-detalhe-parcela {
    display: flex;
    flex-direction: column;
}

.card-detalhe-parcela-numero {
    display: flex;
}

.wrap-valor-detalhe-parcela>div>span, .card-detalhe-parcela-numero>span:first-of-type {
    font-size: 13px;
    color: grey;
    font-weight: normal;
    letter-spacing: normal;
}

.card-detalhe-parcela-numero>span:first-of-type {
    margin: auto;
    margin-right: 10px;
}

.wrap-valor-detalhe-parcela>div {
    color: #414141;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin: auto 0px;
}

@media (max-width: 350px) {
    .card-detalhe-parcela>span {
        font-size: 23px;
    }

    .wrap-valor-detalhe-parcela>div>span, .card-detalhe-parcela-numero>span:first-of-type {
        font-size: 12px;
    }

    .wrap-valor-detalhe-parcela>div {
        font-size: 14px;
    }
}

.wrap-detalhes-modal-cards {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.wrap-detalhes-modal-cards::-webkit-scrollbar {
    width: 7px;
}
  
  /* Track */
.wrap-detalhes-modal-cards::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px whitesmoke; 
    border-radius: 10px;
}
  
  /* Handle */
.wrap-detalhes-modal-cards::-webkit-scrollbar-thumb {
    background: #737372; 
    border-radius: 10px;
}

@-webkit-keyframes open {

    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }

  }

@keyframes open {

    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }

  }
.card-detalhe-parcela {
    display: flex;
    height: 50px;
    margin-top: 15px;
    flex-direction: row;
    border: 1px solid #414141;
    justify-content: space-between;
    padding: 0px 5%;
    border-radius: 10px;
    box-shadow: 0px 3px 2px 0px rgba(65, 65, 65, 0.33);
}
.card-detalhe-parcela:first-child {
    margin-top:0;
}

.card-detalhe-parcela-numero>span:last-of-type {
    font-size: 30px;
    font-weight: bold;
    color: #DA2129;
    line-height: 50px;
}

.wrap-valor-detalhe-parcela {
    display: flex;
    flex-direction: column;
}

.card-detalhe-parcela-numero {
    display: flex;
}

.wrap-valor-detalhe-parcela>div>span, .card-detalhe-parcela-numero>span:first-of-type {
    font-size: 13px;
    color: grey;
    font-weight: normal;
    letter-spacing: normal;
}

.card-detalhe-parcela-numero>span:first-of-type {
    margin: auto;
    margin-right: 10px;
}

.wrap-valor-detalhe-parcela>div {
    color: #414141;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin: auto 0px;
}

@media (max-width: 520px) {

    .card-detalhe-parcela-numero>span:first-of-type {
        margin: auto;
        margin-right: 0;
        width: 45px;
    }

    .card-detalhe-parcela-numero>span:last-of-type {
        font-size: 20px;
        font-weight: bold;
        color: #DA2129;
        line-height: 50px;
    }

    .card-detalhe-parcela>span {
        font-size: 12px;
    }

    .wrap-valor-detalhe-parcela>div>span, .card-detalhe-parcela-numero>span:first-of-type {
        font-size: 12px;
    }

    .wrap-valor-detalhe-parcela>div {
        font-size: 14px;
    }
}
.tela-inicial-text-boleto {
	color: #da2129;
	font-family: Roboto-Regular;
	font-weight: 600;
	font-size: 2em;
	padding: 5%;
	padding-bottom: 0;
	text-align: left;
}

.tela-inicial-text-boleto img {
	width: 30%;
}

.tela-inicial-sifrao {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	width: 50px;
}

.botao-gerar-boleto {
	background-color: #737372;
	margin-top: 5px;
	padding-top: 2%;
	padding-bottom: 2%;
	width: 350px;
	text-align: center;
	word-break: unset;
	border: none;
	outline: none;
	color: white;
	border-radius: 15px;
}

.Button-detalhes {
	background-color: #da2129;
	border-color: #da2129;
	margin-top: 12px;
	border: 57px;
	text-align: center;
	padding-top: 2%;
	padding-bottom: 2%;
	width: 350px;
	border: none;
	outline: none;
	color: white;
	border-radius: 15px;
}

.impressora {
	margin-left: -4%;
	width: 108% !important;
	position: relative;
	z-index: 0;
}
.flex-logo {
	width: 35% !important;
	padding: 5%;
	position: relative;
	z-index: 0;
}

.checkbox {
	margin-top: 8px;
	margin-bottom: 8px;
	text-align: center;
	color: whitesmoke;
}

.Container-inicial {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: left;
}

.Container-inicial {
	width: 100%;
	min-height: 100vh;
}

.Container-final {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: left;
}

.Container-final {
	position: fixed;
	width: 100vw;
	height: 100vh;
	padding: 0;
}

.Cabecalho-white {
	text-align: center;
	color: white;
	font-family: Roboto-Bold;
	font-weight: bold;
	font-size: 1.7em;
	padding: 5px;
	padding-top: 0;
	padding-bottom: 0;
}

.Cabecalho {
	text-align: center;
	color: white;
	font-family: Roboto-Bold;
	font-weight: bold;
	font-size: 1.7em;
	padding-top: 0;
	padding-bottom: 0;
}

.sifrao {
	width: 22px !important;
}

.wholePaper {
	display: flex;
	flex-direction: column;
	width: 400px;
	margin-bottom: 25px;
	-webkit-animation: openBoleto 2s;
	        animation: openBoleto 2s;
	align-items: center;
	box-sizing: border-box;
}

.PapelTop {
	box-sizing: border-box;
	background-color: white;
	border-radius: 15px;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	background-image: linear-gradient(to right, black 65%, rgba(255, 255, 255, 0) 0%);
	background-position: bottom;
	background-size: 15px 1px;
	background-repeat: repeat-x;
	display: flex;
	flex-direction: column;
}

.MiniFlex {
	width: 40%;
	padding-top: 10%;
	padding-left: 15px;
}

.LinhaUm {
	display: flex;
	justify-content: space-between;
	padding: 5% 5% 0px 5%;
}

.LinhaUm > * {
	text-align: center;
}

.ajust {
	margin-top: 60px;
}

.Title-gerado {
	margin-top: 5px;
	font-size: 1em;
	text-align: left;
	font-family: museo700;
	white-space: nowrap;
}

.Title {
	font-size: 1em;
	font-family: museo700;
	white-space: nowrap;
}

.Title1 {
	font-size: 1.1em;
	font-family: museo700;
	white-space: nowrap;
}

.Title2 {
	font-size: 1.1em;
	text-align: left;
	margin-top: -10%;
	font-family: museo700;
	white-space: nowrap;
}

.Title3 {
	margin-top: 5px;
	font-size: 1em;
	font-family: museo700;
	word-break: unset;
	word-wrap: unset;
	overflow-wrap: unset;
	-webkit-hyphens: unset;
	hyphens: unset;
}

.subTitleSpace {
	font-weight: 100;
	color: black;
	font-size: 1em;
	white-space: nowrap;
	overflow-x: hidden;
	margin-top: 17.3px;
	margin-bottom: 30px;
	font-family: museo;
}

.subTitleSpace2 {
	font-weight: 100;
	color: black;
	font-size: 1em;
	word-break: break-all;
	margin-top: calc(12.5% + 10px);
	margin-bottom: 30px;
	text-align: left;
	font-family: museo;
}

.TitleSpace {
	font-size: 1em;
	padding-top: 20px;
	white-space: nowrap;
	font-family: museo700;
}

.Texto {
	font-weight: 100;
	color: black;
	font-size: 1em;
	white-space: nowrap;
	font-family: museo;
	height: 45px;
}

.Linha {
	display: flex;
	justify-content: space-around;
	padding: 5%;
	align-items: center;
}

.Button {
	font-family: museo;
	font-size: 0.8em;
	background-color: #737372;
	padding-top: 5%;
	padding-bottom: 5%;
	border: none;
	outline: none;
	color: white;
}

.Button,
.Button-White {
	font-family: museo700;
	background-size: cover;
	padding-top: 1%;
	padding-bottom: 1%;
	border: none;
	outline: none;
	color: white;
	border-radius: 10px;
	margin-bottom: 2%;
	margin-top: 2%;
	margin-left: 8%;
	font-weight: bold;
}

.Button-White {
	background-color: #383838;
	color: white;
	font-size: 15px;
}

.Button,
.Button-Black {
	font-family: museo700;
	background-size: cover;
	padding-top: 1%;
	padding-bottom: 1%;
	border: none;
	outline: none;
	color: white;
	border-radius: 10px;
	margin-bottom: 2%;
	margin-top: 2%;
	margin-left: 8%;
	font-weight: bold;
}

.Button-Black {
	background-color: #383838;
	color: white;
	font-size: 15px;
}

.Button2 {
	font-family: museo;
	font-size: 0.8em;
	background-color: #737372;
	padding: 5%;
	border: none;
	text-align: center;
	outline: none;
	color: white;
	border-radius: 5px;
}

.center-center {
	display: flex !important;
	align-items: center;
	justify-content: center !important;
}
.fs-35 {
	font-size: 35px;
}

.ajustModal {
	position: relative;
	flex-direction: column;
	justify-content: center;
	width: 32em;
	max-width: 100%;
}

.PapelBottom {
	background-color: white;
	border-radius: 15px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	min-height: 150px;
}

.Bottom {
	text-align: center;
}

.TitleBottom {
	font-family: museo900;
	font-size: 1em;
	text-align: center;
}

.TitleBottom2 {
	font-family: Roboto-Regular;
	font-size: 1.3em;
}

.labelButton {
	text-align: center;
	color: white;
	font-size: 1em;
	margin-top: 2%;
}

.labelButtonBig {
	color: whitesmoke;
	font-family: museo900;
	font-size: 0.7em;
	margin-top: 10%;
	margin-bottom: 10px;
}

.labelSMS {
	font-family: museo;
	color: white;
	font-size: 1.4em;
	padding: 10px 10px;
	padding-bottom: 10px;
	text-align: center;
}

.labelNaoEspere {
	color: #ffbd1e;
	text-align: center;
	margin-top: 30px;
	margin-bottom: -5px;
}

.div-select {
	width: 250px;
	overflow: hidden;
}

.class-select {
	background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-select.gif) no-repeat #354880;
	background-position: 205px center;
	width: 270px;
	height: 48px;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 18px;
	padding: 13px 20px 13px 12px;
	color: #fff;
	text-indent: 0.01px;
	text-overflow: '';
}

.select-parcela,
.select-vencimento {
	background-color: #da2129;
	height: 30px;
	width: 90%;
	font-size: 0.8em;
	color: white;
	border-radius: 5px;
	outline: none;
	font-family: museo700;
	margin-top: 5px;
	margin-left: -1px;
	box-sizing: border-box;
	-webkit-appearance: none; 
	background: url(/static/media/seta.87e027b4.svg) no-repeat right center #da2129;
	background-size: 12px 12px;
	background-position-x: 95%;
	padding-left: 5px;
	padding-right: 20px;
	border: none;
	cursor: pointer;
}

.select-vencimento {
	text-align: right !important;
}

.Boleto {
	width: 30%;
}

.tela-inicial-sifrao {
	width: 20px;
}

.itensBottom {
	width: 60%;
	text-align: right;
}
.textoBottom {
	font-size: 0.7em;
	font-family: Roboto-Regular;
	text-align: right;
}

/* Mobiles */
@media (max-width: 569px) {
	.Cabecalho {
		text-align: left;
	}
	.wholePaper {
		width: 80%;
	}
	.PapelBottom {
		width: 100%;
	}

	.PapelTop {
		width: 100%;
	}

	.tela-inicial-sifrao img {
		width: 15px;
	}

	.impressora {
		width: 15px;
	}

	.labelButtonBig {
		font-size: 11px !important;
	}
	.Button,
	.Button-White {
		font-size: 13px !important ;
		width: 87%;
	}

	.Cabecalho-white {
		width: 90%;
	}
	.botao-gerar-boleto {
		width: 100%;
		height: 35px;
	}

	.Button-detalhes {
		width: 100%;
		height: 35px;
	}

	.textoBottom {
		font-size: 0.55em;
		font-family: Roboto-Regular;
		text-align: right;
	}

	.select-parcela,
	.select-vencimento {
		font-size: 0.7em;
	}

}

@media only screen and (max-width: 320px) {
	.MuiModal-root-15 {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1300;
		position: fixed;
		width: 105%;
	}
	.tela-inicial-text-boleto {
		font-size: 1.5em;
	}
	.Title2 {
		font-size: 0.9em;
	}
	.Title1 {
		font-size: 0.9em;
	}
	.Title {
		font-size: 0.9em;
	}

	.TitleSpace {
		font-size: 0.9em;
	}

	.Title3 {
		font-size: 0.9em;
	}
	.textoBottom {
		font-size: 0.50em;
		font-family: Roboto-Regular;
		text-align: right;
	}
	.select-parcela,
	.select-vencimento {
		font-size: 0.7em;
	}
}

@media only screen and (orientation: portrait) {
	.Boleto {
		width: 30%;
		margin-right: 5%;
	}
}

@media only screen and (orientation: landscape) {
	.Boleto {
		width: 30%;
		margin-right: 2%;
	}
}

.tela-inicial-boleto-direita{ 
	text-align: right;
	width: 45%;
}

.tela-inicial-boleto-esquerda {
	width: 45%;
	text-align: left;
}

.tela-inicial-selects {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 5% 5% 5%;
}

.tela-inicial-vencimentos {
	width: 50%;
	text-align: right;
}

.tela-inicial-parcelas {
	width: 50%;

}
.container-pesquisa-satisfacao {
    position: fixed;
    top: 0px;
    min-height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.none{
    display: none;
}
.div-pesquisa {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 280px;
    padding: 20px;
    min-height: 325px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 1);
}

.div-pesquisa-text {
    display: flex;
    flex-direction: column;
}
.div-pesquisa-text>span:first-of-type {
    color: #da2129;
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 15px;
}

.div-pesquisa-text>span:last-of-type {
    color: #414141;
    font-size: 17px;
}

.div-pesquisa-button>button {
    height: 40px;
    width: 200px;
    text-align: center;
    margin-bottom: 0px;
    background-color: #da2129;
    color: white;
    border: none;
    border-radius: 15px;
    font-size: 18px;
}

.div-pesquisa-button>button:disabled {
    background-color: lightgray;
}

.div-close {
    width: 100%;
    text-align: right;
    cursor: pointer;
}
.div-close img {
    width: 15px;
}

.close {
    width: 6mm;
}

/* bloco das estrelas */

.rate {
    float: left;
    height: 46px;
    padding: 0 10px;
}

.rate:not(:checked)>input {
    position: absolute;
    top: -9999px;
}

.rate:not(:checked)>label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 3.2em;
    color: #ccc;
}

.rate:not(:checked)>label:before {
    content: '★ ';
}

.rate>input:checked~label {
    color: #da2129;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
    color: #da2129;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
    color: #ef1c23;
}
.LinhaUm-boleto-gerado {
	display: flex;
	flex-direction: column;
	padding: 5%;
}



.linha2-boleto-gerado {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.tela-inicial-text-boleto img {
	width: 30%;
}

.subTitle-boleto-gerado {
	font-weight: 100;
	color: black;
	font-size: 1em;
	white-space: nowrap;
	overflow-x: hidden;
	font-family: museo;
}

.botao-copiar-boleto-gerado {
	background-color: #da2129;
	padding-top: 5%;
	padding-bottom: 5%;
	border: none;
	outline: none;
	text-align: center;
	color: white;
	border-radius: 5px;
}

.textoBottom-boleto-gerado {
	font-size: 0.7em;
	font-family: Roboto-Regular;
	text-align: right;
}

.Title-gerado-boleto {
	font-size: 1em;
	text-align: left;
	font-family: museo700;
	white-space: nowrap;
}

.Container-boleto {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: left;
}

.itensBottom {
	width: 55%;
	text-align: right;
}

.Container-boleto {
	width: 100%;
	min-height: 100vh;
}

.Button-boleto-gerado {
	/* font-family: museo; */
	background-color: #da2129;
	margin-top: 5px;
	padding-top: 2%;
	padding-bottom: 2%;
	width: 350px;
	text-align: center;
	word-break: unset;
	border: none;
	outline: none;
	color: white;
	border-radius: 10px;
}

@media only screen and (max-width: 565px) {

	.Button-boleto-gerado {
		width: 90%;
	}

	.LinhaUm-boleto-gerado {
		display: flex;
		flex-direction: column;
		padding: 5%;
	}

	.linha2-boleto-gerado {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.subTitle-boleto-gerado {
		font-weight: 100;
		color: black;
		font-size: 1em;
		white-space: nowrap;
		overflow-x: hidden;
		font-family: museo;
	}

	.botao-copiar-boleto-gerado {
		padding-top: 5%;
		padding-bottom: 5%;
		border: none;
		outline: none;
		text-align: center;
		color: white;
		border-radius: 5px;
		font-size: 0.7em;
	}

	.textoBottom-boleto-gerado {
		font-size: 0.70em;
		font-family: Roboto-Regular;
		text-align: right;
	}

	.itensBottom {
		width: 60%;
		text-align: right;
	}
	@media (max-width: 400px) and (min-height: 650px) {
		.itensBottom {
			width: 65%;
			text-align: right;
		}
	
		.textoBottom-boleto-gerado {
			font-size: 0.60em;
			font-family: Roboto-Regular;
			text-align: right;
		}
	}
}
@media only screen and (max-width: 360px) {
	.Container-boleto {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: left;
	}

	.Container-boleto {
		position: initial;
		width: 100%;
		height: 100%;
	}
}

@media only screen and (max-width: 475px) {
	.Container-boleto {
		width: 100%;
		height: -1%;
		position: initial;
	}
}

.container-tela-pdf {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	justify-content: space-around;
	align-items: center;
}

.tela-pdf-logo {
	display: flex;
	justify-content: center;
}

.tela-pdf-logo img {
	width: 15%;
	min-width: 250px;
}

.tela-pdf-iframe {
	display: flex;
  justify-content: center;
  background: gray;
  min-width: 70%;
}

.tela-pdf-iframe iframe{
  border: none;
  outline: none;
  min-height: 65vh;
  width: 100%;
}

.tela-pdf-button {
	display: flex;
	justify-content: center;
	align-items: center;
}

.tela-pdf-button button {
	text-align: center;
	background-color: #da2129;
  width: 250px;
  border: 57px;
	text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
	border: none;
	outline: none;
	color: white;
  border-radius: 10px;
  font-family: museo700;
}

@media only screen and (max-width: 720px) and (orientation: landscape) {

  .tela-pdf-iframe {
    width: 90%;
  }

  .tela-pdf-iframe iframe{
    height: 300px;
  }

  .tela-pdf-logo {
    margin: 25px;
  }

  .tela-pdf-button button {
    margin: 25px;
  }
}
@media only screen and (max-width: 720px) and (orientation: portrait) {

  .tela-pdf-iframe {
    width: 90%;
  }

}


.container-tela-desconhece {
    background-color: #373736;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.tela-desconhece-logo {
    width: 45%;
    min-width: 250px;
}

.tela-desconhece-items {
    width: 75%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.tela-desconhece-success {
    display: flex;
    justify-content: center;
}

.tela-desconhece-success img {
    width: 125px;
}

.tela-desconhece-text {
    color: #fff;
    font-size: 1.5em;
    font-family: museo700
}
