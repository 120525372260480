.container-pesquisa-satisfacao {
    position: fixed;
    top: 0px;
    min-height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.none{
    display: none;
}
.div-pesquisa {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 280px;
    padding: 20px;
    min-height: 325px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 1);
}

.div-pesquisa-text {
    display: flex;
    flex-direction: column;
}
.div-pesquisa-text>span:first-of-type {
    color: #da2129;
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 15px;
}

.div-pesquisa-text>span:last-of-type {
    color: #414141;
    font-size: 17px;
}

.div-pesquisa-button>button {
    height: 40px;
    width: 200px;
    text-align: center;
    margin-bottom: 0px;
    background-color: #da2129;
    color: white;
    border: none;
    border-radius: 15px;
    font-size: 18px;
}

.div-pesquisa-button>button:disabled {
    background-color: lightgray;
}

.div-close {
    width: 100%;
    text-align: right;
    cursor: pointer;
}
.div-close img {
    width: 15px;
}

.close {
    width: 6mm;
}

/* bloco das estrelas */

.rate {
    float: left;
    height: 46px;
    padding: 0 10px;
}

.rate:not(:checked)>input {
    position: absolute;
    top: -9999px;
}

.rate:not(:checked)>label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 3.2em;
    color: #ccc;
}

.rate:not(:checked)>label:before {
    content: '★ ';
}

.rate>input:checked~label {
    color: #da2129;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
    color: #da2129;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
    color: #ef1c23;
}